import React, { useState } from "react";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { Helmet } from "react-helmet";

import { QueryFragments } from "../GraphQl/queryFragments"; // eslint-disable-line
import { PageMeta } from "../components/pageMeta";
import { PostGrid } from "@modules/postGrid";

import CarrotDown from "@svg/carrotdown.svg";

const Chevron = ({ isOpen }) => {
  const variant = isOpen ? "opened" : "closed";

  const chevVars = {
    closed: {
      rotate: 0,
      transition: {
        duration: 0.3,
      },
    },
    opened: {
      rotate: 180,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <motion.div variants={chevVars} initial="closed" animate={variant}>
      <CarrotDown />
    </motion.div>
  );
};

/* Mobile Dropdown */
const Accordion = ({
  i,
  expanded,
  setExpanded,
  content,
  activeTab,
  setActiveTab,
  categories,
}) => {
  const isOpen = i === expanded;
  return (
    <>
      <motion.div
        className="pt-16 pb-2 border-b border-navy flex flex-row justify-between items-center"
        initial={false}
        onClick={() => setExpanded(isOpen ? false : i)}
      >
        <p className="text-lg leading-lg text-navy font-bold capitalize">
          {activeTab === "all" ? "All" : categories[activeTab].title}
        </p>
        <Chevron isOpen={isOpen} />
      </motion.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <motion.div
              variants={{
                collapsed: { scale: 1, opacity: 0 },
                open: { scale: 1, opacity: 1 },
              }}
              transition={{ duration: 0.5 }}
              className="text-left pt-4"
            >
              {activeTab !== "all" && (
                <button
                  onClick={() => setActiveTab("all")}
                  className="pb-2 flex text-lg leading-lg focus:outline-none"
                >
                  All
                </button>
              )}
              {content?.map(
                (c, i) =>
                  c?.title?.toLowerCase() !==
                  categories[activeTab]?.title.toLowerCase() && (
                    <button
                      onClick={() => setActiveTab(i)}
                      className="pb-2 flex flex-col text-lg leading-lg focus:outline-none"
                    >
                      {c?.title}
                    </button>
                  )
              )}
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export const BlogTab = ({ categories, posts, pageContext }) => {
  const [expanded, setExpanded] = useState(false);

  try {
    var title = pageContext.slug.includes("-")
      ? pageContext.slug.replace("-", " ")
      : pageContext.slug;
  } catch (error) {
    var title = "Media";
  }

  const currentPage = pageContext.slug;
  const [currentMeta, setCurrentMeta] = useState({
    metaDescription: "touch compass media",
    metaTitle: "Media",
    _key: null,
    _rawMetaImage: null,
  });

  const [activeTab, setActiveTab] = useState("all");
  const allPosts =
    activeTab === "all"
      ? posts.nodes
      : posts.nodes.filter(
        (post) =>
          post.category.slug.current === categories[activeTab].slug.current
      );

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#1E1B43"></meta>
      </Helmet>
      <div className="bg-purple-100">
        <PageMeta {...currentMeta} />
        <div className="pt-20 lg:pt-60 grid grid-cols-14 text-navy overflow-hidden text-left">
          <h1 className="col-start-2 col-end-14 text-2xl leading-2xl md:text-5xl md:leading-5xl font-helix-bold">
            News
          </h1>
          <h2 className="col-start-2 col-end-14 text-purple-200 text-2xl leading-2xl md:text-5xl md:leading-5xl">
            Rongorongo
          </h2>

          {/* DESKTOP TABS */}
          <div className="hidden lg:block cursor-none col-start-1 col-end-15 lg:col-start-2 lg:col-end-14">
            <div className="mx-8 flex flex-row justify-between items-center lg:justify-start lg:mx-auto lg:gap-8 mt-16 lg:mt-24 no-transform text-xs md:text-lg capitalize">
              <AnimateSharedLayout>
                <div className="relative">
                  <button
                    onClick={() => setActiveTab("all")}
                    className="px-3 pb-2 text-2xl leading-2xl transform -translate-y-0.5 focus:outline-none"
                  >
                    All
                  </button>
                  {activeTab === "all" && (
                    <motion.span
                      layoutId="line"
                      className="w-full h-1 bg-navy block absolute"
                      style={{ bottom: ".5px" }}
                    />
                  )}
                </div>
                {categories.map((c, i) => {
                  return (
                    <div className="relative">
                      <button
                        key={i}
                        onClick={() => setActiveTab(i)}
                        className="px-3 pb-2 whitespace-nowrap text-2xl leading-2xl transform -translate-y-0.5 focus:outline-none relative"
                      >
                        {c.title}
                      </button>
                      {activeTab === i && (
                        <motion.span
                          layoutId="line"
                          className="w-full h-1 bg-navy block absolute z-20"
                          style={{ bottom: ".5px" }}
                        />
                      )}
                    </div>
                  );
                })}
              </AnimateSharedLayout>
            </div>
          </div>

          {/* MOBILE TABS DROPDOWN */}
          <div className="lg:hidden col-start-2 col-end-14">
            <Accordion
              categories={categories}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              i={0}
              expanded={expanded}
              setExpanded={setExpanded}
              content={categories}
              title={title}
            />
          </div>

          {/* Tabs Border */}
          <div className="hidden lg:block w-full border-navy border-t col-start-1 col-end-15 lg:col-start-2 lg:col-end-14 transform -translate-y-0.5" />
        </div>

        <div className="pt-10 lg:pt-24 pb-40 lg:pb-48">
          <PostGrid posts={allPosts} showCategory={true} />
        </div>
      </div>
    </>
  );
};
