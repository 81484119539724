import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import { QueryFragments } from "../GraphQl/queryFragments"; // eslint-disable-line
import Layout from "../components/layout";
import { useBlogCategory } from "../GraphQl/useBlogCategory";
import { BlogTab } from "./blogTab";

const BlogPortal = ({ pageContext, data: { posts } }) => {
  const categories = useBlogCategory();

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#1E1B43"></meta>
      </Helmet>
      <Layout>
        <BlogTab
          categories={categories}
          posts={posts}
          pageContext={pageContext}
        />
      </Layout>
    </>
  );
};

export default BlogPortal;

export const pageQuery = graphql`
  query blogPortalQuery($skip: Int!, $limit: Int!) {
    posts: allSanityBlog(
      sort: { fields: orderRank, order: ASC }
      skip: $skip
      limit: $limit # filter: { order: { gte: 0 } }
    ) # sort: { order: ASC, fields: order }
    {
      nodes {
        id
        date
        heading
        excerpt
        content: _rawContent(resolveReferences: { maxDepth: 10 })
        slug {
          current
        }
        image {
          ...ImageWithPreview
        }
        category {
          slug {
            current
          }
        }
      }
    }
  }
`;
