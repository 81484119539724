import { graphql, useStaticQuery } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useBlogCategory = () => {
  const { allSanityBlogCategory } = useStaticQuery(graphql`
    query {
      allSanityBlogCategory {
        nodes {
          title
          slug {
            current
          }
          meta {
            _key
            _type
            metaTitle
            metaDescription
            _rawMetaImage
          }
        }
      }
    }
  `);
  return allSanityBlogCategory.nodes || {};
};
